import { ReactElement } from 'react'
import configuration from '~/configuration'
import { IRole } from '~/core/@types/global'
import { SpinnerIcon } from '~/core/ui/FillIcons'
import { adminCanAction } from '~/core/utilities/permission'
import useBoundStore from '~/lib/store'
import { ITenantPermissionSettingType } from '~/lib/store/permission-setting-slice'

const Spinner = () => (
  <div className="flex h-screen flex-1 items-center justify-center">
    <SpinnerIcon />
  </div>
)

const withPermissionFeatureProvider = <T extends object>(
  permission: {
    checkAccessPermission: (data: {
      currentRole?: IRole
      permissionSetting: ITenantPermissionSettingType[]
      keyModule: Array<string>
      keyModuleObject: Array<string>
      action: string
    }) => boolean
    keyModule: Array<string>
    keyModuleObject: Array<string>
    action: string
  },
  Component: (props: T) => ReactElement | null
) => {
  const WithPermissionFeature = (props: T) => {
    const { permissionSetting, user, currentRole } = useBoundStore()

    if (adminCanAction(currentRole?.code)) {
      return <Component {...(props as T)} />
    }

    if (permissionSetting?.length === 0 || !user.id) {
      return <Spinner />
    }

    ;(() => {
      if (
        permissionSetting.length &&
        currentRole?.code &&
        !adminCanAction(currentRole?.code)
      ) {
        if (
          !permission.checkAccessPermission({
            currentRole,
            permissionSetting,
            keyModule: permission.keyModule,
            keyModuleObject: permission.keyModuleObject,
            action: permission.action
          })
        ) {
          window.location.href = configuration.path.errorAccessDenied
        }
      }
    })()

    if (adminCanAction(currentRole?.code)) {
      return <Component {...(props as T)} />
    }

    return permission.checkAccessPermission({
      currentRole,
      permissionSetting,
      keyModule: permission.keyModule,
      keyModuleObject: permission.keyModuleObject,
      action: permission.action
    }) ? (
      <Component {...(props as T)} />
    ) : (
      <Spinner />
    )
  }

  return WithPermissionFeature
}

export default withPermissionFeatureProvider
