'use client'

import { cn } from '~/core/ui/utils'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const TypographyH1: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h1
      className={cn(
        'scroll-m-20 text-4xl font-extrabold tracking-tight',
        className
      )}>
      {children}
    </h1>
  )
}

const TypographyH2: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h2
      className={cn(
        'scroll-m-20 text-3xl font-semibold tracking-tight',
        className
      )}>
      {children}
    </h2>
  )
}

const TypographyH3: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h3
      className={cn(
        'scroll-m-20 text-2xl font-semibold tracking-tight',
        className
      )}>
      {children}
    </h3>
  )
}

const TypographyH4: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h4
      className={cn(
        'scroll-m-20 text-xl font-semibold tracking-tight',
        className
      )}>
      {children}
    </h4>
  )
}

const TypographyH5: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h4
      className={cn(
        'scroll-m-20 text-lg font-semibold tracking-tight',
        className
      )}>
      {children}
    </h4>
  )
}

export { TypographyH1, TypographyH2, TypographyH3, TypographyH4, TypographyH5 }
