import {
  addDays,
  addMinutes,
  compareDesc,
  differenceInMinutes,
  format
} from 'date-fns'
import { UseFormSetValue } from 'react-hook-form'
import {
  DataSubmitCallbackType,
  SelectDurationType
} from '~/components/Calendar/ScheduleInterview/DateTimeMethodCalendar'
import { ISelectOption } from '~/core/ui/Select'
import { getTimeZone } from '~/core/utilities/common'
import {
  DATE_FORMAT_YYYYMMDD,
  timeFormatDate,
  timeFullFormatDate
} from '~/core/utilities/format-date'
import { mappingScheduleInterview } from '../../interviews/mapping/schedule-interview-mapping'
import { FirstStepFormType, InterviewDetailType } from '../types'
import {
  AGENCY_GROUPING_ATTENDEE_ID,
  DATE_TIME_TYPE_VALUE,
  GROUPING_ATTENDEE_ID
} from './enum.cva'
import { TFunction } from 'next-i18next'

export const getTimeSelectOptions = ({
  formatAMOrPM = false,
  isDisablePastTime = false,
  selectedDate = new Date()
}: {
  formatAMOrPM?: boolean
  isDisablePastTime?: boolean
  selectedDate?: Date
}): Array<ISelectOption> => {
  const timeArr = [] as Array<string>
  ;[...Array(24).keys()].map((hour) => {
    timeArr.push(`${('0' + hour).slice(-2)}:00`)
    timeArr.push(`${('0' + hour).slice(-2)}:15`)
    timeArr.push(`${('0' + hour).slice(-2)}:30`)
    timeArr.push(`${('0' + hour).slice(-2)}:45`)
  })
  const formatTimeName = (time: string) => {
    const date = new Date(`2024-01-01T${time}:00`)
    return formatAMOrPM
      ? date.getHours() > 11
        ? `${(
            '0' +
            (date.getHours() === 12 ? date.getHours() : date.getHours() % 12)
          ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)} PM`
        : `${('0' + date.getHours()).slice(-2)}:${(
            '0' + date.getMinutes()
          ).slice(-2)} AM`
      : time
  }

  const checkDisableTime = (time: string) => {
    const date = new Date(`2024-01-01T${time}:00`)
    const currentDate = new Date()
    const dateSelectedOnly = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    )
    const dateCurrentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    )
    if (isDisablePastTime) {
      if (
        selectedDate &&
        compareDesc(dateSelectedOnly, dateCurrentDateOnly) === 1
      ) {
        return true
      }

      if (
        selectedDate &&
        compareDesc(dateSelectedOnly, dateCurrentDateOnly) === -1
      ) {
        return false
      }

      if (date.getHours() > currentDate.getHours()) {
        return false
      } else {
        if (
          date.getHours() === currentDate.getHours() &&
          date.getMinutes() >= currentDate.getMinutes()
        ) {
          return false
        }
        return true
      }
    }
    return false
  }

  return timeArr.map((value) => ({
    value: value,
    disabled: checkDisableTime(value),
    supportingObj: {
      name: formatTimeName(value)
    }
  }))
}

export const roundUpToNearestTwoHour = (date: Date) => {
  date.setMinutes(60)
  date.setMinutes(0, 0, 0)
  date.setMinutes(60)
  return date
}

export const changeTimezone = ({
  date,
  timezone
}: {
  date: string | Date
  timezone?: string
}): Date => {
  return new Date(
    new Date(date).toLocaleString('en-US', {
      timeZone: timezone
    })
  )
}

const TZ_REGEX = /[-\+][0-9]*:[0-9]*/
export const removeTzFromDate = (date: string) => {
  return !!date?.match(TZ_REGEX)
    ? new Date(date.slice(0, -6))
    : /Z$/.test(date)
    ? new Date(date.slice(0, -1))
    : new Date(date)
}

export const addTzToDate = (date: string, timezone: string) => {
  const dateTimeOnly = removeTzFromDate(date)
  const removedTzDateStr = `${format(
    dateTimeOnly,
    DATE_FORMAT_YYYYMMDD
  )}T${timeFullFormatDate(dateTimeOnly)}`

  const tz = convertTimezone(timezone)

  return `${removedTzDateStr}${tz}`
}

export const convertDurationAmountMinutes = (duration?: string) => {
  let amountMins = 1
  switch (duration) {
    case '30_mins':
      amountMins = 30
      break
    case '1_hour_30_mins':
      amountMins = 90
      break
    case '2_hour':
      amountMins = 120
      break
    case '2_hour_30_mins':
      amountMins = 150
      break
    case '3_hour':
      amountMins = 180
      break

    default:
      amountMins = 60
  }

  return amountMins
}

export const changeDuration = ({
  selectedSlots,
  duration,
  deviceTimezone
}: {
  selectedSlots: SelectDurationType
  duration: string
  deviceTimezone?: string
}) => {
  if (Object.keys(selectedSlots).length > 0) {
    const amountMins = convertDurationAmountMinutes(duration)

    const convertedSelectedArr = Object.keys(selectedSlots).reduce(
      (result, key) => {
        const endTimeDate = addMinutes(selectedSlots[key]?.start, amountMins)

        return {
          ...result,
          [key]: {
            ...selectedSlots[key],
            end: endTimeDate,
            endStr: `${format(
              endTimeDate,
              DATE_FORMAT_YYYYMMDD
            )}T${timeFormatDate(endTimeDate)}:00${deviceTimezone}`
          }
        }
      },
      {} as SelectDurationType
    )

    return convertedSelectedArr
  }

  return selectedSlots
}

export const formatDateWithTz = (date: Date, timezone?: string) => {
  return `${format(date, DATE_FORMAT_YYYYMMDD)}T${timeFormatDate(
    date
  )}:00${timezone}`
}

export const getTimeSlots = (
  data: FirstStepFormType
): Array<{
  fromDatetime?: string
  toDatetime?: string
  timezone?: string
}> => {
  const formatDateTime = ({
    date,
    time,
    timezone
  }: {
    date?: Date
    time?: string
    timezone?: string
  }) => {
    const tz = convertTimezone(timezone)
    let dateFormat = date || new Date()
    if (time) {
      const hourAndMin = time.split(':')
      dateFormat.setHours(Number(hourAndMin?.[0]), Number(hourAndMin?.[1]), 0)
    }

    return formatDateWithTz(dateFormat, tz)
  }
  return data?.method === DATE_TIME_TYPE_VALUE.specific
    ? [
        {
          fromDatetime: formatDateTime({
            date: data?.date,
            time: data?.startTime?.value,
            timezone: data?.timezone?.value
          }),
          toDatetime: formatDateTime({
            date: data?.date,
            time: data?.endTime?.value,
            timezone: data?.timezone?.value
          }),
          timezone: data?.timezone?.value
        }
      ]
    : data?.timeSlots || []
}

export const formatStartEndDate = ({
  startTime,
  endTime,
  date,
  timezone,
  timeOptions
}: {
  timeOptions: ISelectOption[]
  startTime?: string
  endTime?: string
  date?: Date
  timezone?: string
}) => {
  const indexStartTime = timeOptions.findIndex(
    (time) => time.value === startTime
  )
  const indexEndTime = timeOptions.findIndex((time) => time.value === endTime)
  const startDate = date || new Date()
  const endDate = indexStartTime > indexEndTime ? addDays(startDate, 1) : date
  return {
    start: `${
      startDate && format(startDate, DATE_FORMAT_YYYYMMDD)
    }T${startTime}:00${timezone}`,
    end: `${
      endDate && format(endDate, DATE_FORMAT_YYYYMMDD)
    }T${endTime}:00${timezone}`
  }
}

export const calculateDurationBetweenTime = ({
  startTime,
  endTime,
  timeOptions
}: {
  startTime?: string
  endTime?: string
  timeOptions: ISelectOption[]
}) => {
  const indexStartTime = timeOptions.findIndex(
    (time) => time.value === startTime
  )
  const indexEndTime = timeOptions.findIndex((time) => time.value === endTime)

  if (indexStartTime < indexEndTime) {
    return differenceInMinutes(
      new Date(`2024-01-01T${endTime}:00`),
      new Date(`2024-01-01T${startTime}:00`)
    )
  } else
    return differenceInMinutes(
      new Date(`2024-01-02T${endTime}:00`),
      new Date(`2024-01-01T${startTime}:00`)
    )
}

export const mappingGroupHiringTeamAttendee = (
  data?: Array<
    ISelectOption & {
      jobHiringMember?: boolean
    }
  >,
  t?: TFunction
) => {
  const hiringTeams =
    data?.filter(
      (
        item: ISelectOption & {
          jobHiringMember?: boolean
        }
      ) => !!item.jobHiringMember
    ) || []
  const otherTeam = data?.filter((item) => !item.jobHiringMember) || []
  const groupResult = [
    {
      label: t ? `${t('interview:hiringTeam')}` : '',
      groupId: GROUPING_ATTENDEE_ID.hiring,
      options: []
    },
    {
      label: t ? `${t('interview:otherMembers')}` : '',
      groupId: GROUPING_ATTENDEE_ID.other,
      options: []
    }
  ].map((group) => {
    return {
      ...group,
      options: [
        ...group.options,
        ...(group.groupId === GROUPING_ATTENDEE_ID?.hiring
          ? hiringTeams
          : otherTeam)
      ]
    }
  })

  return groupResult
}

export const mappingAgencyGroupHiringTeamAttendee = (
  data?: Array<
    ISelectOption & {
      jobHiringMember?: boolean
      userTenants?: {
        userKind?: string
      }[]
    }
  >,
  t?: TFunction
) => {
  const clientTeams =
    data?.filter(
      (item) =>
        !item.jobHiringMember && item.userTenants?.[0]?.userKind === 'client'
    ) || []
  const hiringTeams =
    data?.filter(
      (
        item: ISelectOption & {
          jobHiringMember?: boolean
        }
      ) => !!item.jobHiringMember
    ) || []
  const otherTeam =
    data?.filter(
      (item) =>
        !item.jobHiringMember && item.userTenants?.[0]?.userKind !== 'client'
    ) || []
  const groupResult = [
    {
      label: t ? `${t('interview:clients')}` : '',
      groupId: AGENCY_GROUPING_ATTENDEE_ID.client,
      options: []
    },
    {
      label: t ? `${t('interview:hiringTeam')}` : '',
      groupId: AGENCY_GROUPING_ATTENDEE_ID.hiring,
      options: []
    },
    {
      label: t ? `${t('interview:otherMembers')}` : '',
      groupId: AGENCY_GROUPING_ATTENDEE_ID.other,
      options: []
    }
  ].map((group) => {
    return {
      ...group,
      options: [
        ...group.options,
        ...(group.groupId === AGENCY_GROUPING_ATTENDEE_ID?.hiring
          ? hiringTeams
          : group.groupId === AGENCY_GROUPING_ATTENDEE_ID?.client
          ? clientTeams
          : otherTeam)
      ]
    }
  })

  return groupResult
}

const formatTimeSlot = ({
  fromDatetime,
  toDatetime,
  timezone
}: {
  fromDatetime?: string
  toDatetime?: string
  timezone?: string
}) =>
  fromDatetime && toDatetime && timezone
    ? `${new Date(fromDatetime).toISOString()}--${new Date(
        toDatetime
      ).toISOString()}--${timezone}`
    : ''

export const isDirtyAttendees = ({
  oldData,
  newData
}: {
  oldData?: ISelectOption[]
  newData?: ISelectOption[]
}) => {
  const oldAttendeeIds = (oldData || [])
    ?.map((attendee) => Number(attendee.value))
    .sort()
    .join(',')
  const newAttendeeIds = (newData || [])
    ?.map((attendee) => Number(attendee.value))
    .sort()
    .join(',')
  return oldAttendeeIds !== newAttendeeIds
}
export const isDirtyTimeSlots = ({
  oldData,
  newData
}: {
  oldData?: {
    fromDatetime?: string
    toDatetime?: string
    timezone?: string
  }[]
  newData?: {
    fromDatetime?: string
    toDatetime?: string
    timezone?: string
  }[]
}) => {
  const formattedNewTimeSlots = (newData || [])
    .map((timeSlot) => formatTimeSlot(timeSlot))
    .sort()
    .join(',')
  const formattedOldTimeSlots = (oldData || [])
    .map((timeSlot) => formatTimeSlot(timeSlot))
    .sort()
    .join(',')

  return (
    (newData || []).length !== (oldData || [])?.length ||
    formattedNewTimeSlots !== formattedOldTimeSlots
  )
}
export const setValuesCalendar = ({
  interviewInfo,
  newData,
  setValue,
  caseEditGetPickedTimeSlot
}: {
  interviewInfo?: Partial<InterviewDetailType>
  newData: DataSubmitCallbackType
  setValue: UseFormSetValue<FirstStepFormType>
  caseEditGetPickedTimeSlot: boolean
}) => {
  const oldValues = mappingScheduleInterview(interviewInfo)

  setValue('attendees', newData?.attendees, {
    shouldDirty: isDirtyAttendees({
      oldData: oldValues?.attendees,
      newData: newData?.attendees
    })
  })
  setValue('timezone', newData?.timezone, {
    shouldDirty: newData?.timezone?.value !== oldValues?.timezone?.value
  })
  setValue('method', newData?.method, {
    shouldDirty: newData?.method !== oldValues?.method
  })
  if (
    newData.method === DATE_TIME_TYPE_VALUE.specific ||
    caseEditGetPickedTimeSlot
  ) {
    setValue('date', newData.date, {
      shouldDirty: newData.date?.toISOString() !== oldValues.date?.toISOString()
    })
    setValue('startTime', newData?.startTime, {
      shouldDirty: newData?.startTime?.value !== oldValues?.startTime?.value
    })
    setValue('endTime', newData?.endTime, {
      shouldDirty: newData?.endTime?.value !== oldValues?.endTime?.value
    })
  } else {
    setValue('timeSlots', newData?.timeSlots, {
      shouldDirty: isDirtyTimeSlots({
        oldData: oldValues?.timeSlots,
        newData: newData?.timeSlots
      })
    })
  }
}

export const convertTimezone = (timezone?: string) =>
  getTimeZone(
    new Date().toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'longOffset'
    })
  ) || 'Z'
