export const renderSectionId = (sectionId: string) => `section-${sectionId}`

export const renderNewSectionId = (sectionKey: string) =>
  `new-section-${sectionKey}`

export const blobToFile = (
  theBlob: Blob,
  fileName: string,
  options?: FilePropertyBag
): File => {
  return new File([theBlob], fileName, options)
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const convertFileTypeToFileExtension = (fileType: string) =>
  ({
    'image/vnd.microsoft.icon': 'ico',
    'image/x-icon': 'ico',
    'image/svg+xml': 'svg',
    'image/gif': 'gif',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/jpg': 'jpg'
  }[fileType] || 'jpg')
